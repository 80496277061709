import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";

import { loading }  from "../components/Loading";
import { userKey } from "../storage";
import { email } from "../components/EmailVerification";

export default function Loading() {
    const { isAuthenticated, isLoading, user, getAccessTokenSilently, error, loginWithRedirect } = useAuth0();

    const loadingPage = (
        <loading.RequestLoading />
    );
    const [page, setPage] = useState(loadingPage);

    const navigate = useNavigate();
    useEffect(() => {
        async function setTokenAndUserInfo() {
            if (isLoading) {
              return;
            }
            if (error) {
                setPage(<email.VerifyEmail />);
                return;
            }
            if (!isAuthenticated) {
                loginWithRedirect();
                return;
            }

            const accessToken = await getAccessTokenSilently();
            userKey.setToken(accessToken?.toString() || "");

            navigate("/clusterlist");
        }
        setTokenAndUserInfo();
    }, [isLoading, isAuthenticated, user]);

    return page;
}