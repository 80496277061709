import { useEffect, useState } from "react";

import { rebalance } from "../api/rebalance";
import { useParams } from "react-router";
import { loading } from "../components/Loading";
import { isRebalanceComponentReady } from "../utils/rebalance";
import { ClusterRebalanceStatus } from "../type/rebalance";
import ClusterState from "./ClusterState";
import ClusterAvailableSaving from "./ClusterAvailableSaving";

export default function AvailableSaving() {
    const { clusterID } = useParams();
    const [ rebalanceStatus, setRebalanceStatus ] = useState<ClusterRebalanceStatus>();

    useEffect(() => {
        async function fetchClusterRebalanceStatus() {
            if (clusterID === undefined) {
                return;
            }
            const status = await rebalance.getRebalanceStatus(clusterID);
            if (status.code !== 200) {
                console.error("Failed to fetch cluster rebalance status:", status.message);
                return;
            }

            setRebalanceStatus(status.data);
        }
        fetchClusterRebalanceStatus();
    }, [clusterID])

    if (!rebalanceStatus) {
        return <loading.RequestLoading />;
    }

    if (isRebalanceComponentReady(rebalanceStatus)) {
        return <ClusterState />;
    }
    return <ClusterAvailableSaving />;
}