import { ClusterRebalanceStatus, EC2NodeClassSpec, NodePoolSpec } from "../type/rebalance"

// The threshold is 3 min
const componentLoseConnectionThreshold = 1000 * 60 * 3;

function isRebalanceComponentReady(rebalanceStatus: ClusterRebalanceStatus | undefined): boolean {
    if (!rebalanceStatus || !rebalanceStatus.lastComponentsActiveTime) {
        return false;
    }
    if (rebalanceStatus.lastComponentsActiveTime === "") {
        return false;
    }
    let date = new Date(rebalanceStatus.lastComponentsActiveTime);
    if (isNaN(date.getTime())) {
        return false;
    }
    if (Date.now() - date.getTime() > componentLoseConnectionThreshold) {
        return false;
    }
    return true;
}

const cpuCoresKey = "karpenter.k8s.aws/instance-cpu";
const memoryMiBKey = "karpenter.k8s.aws/instance-memory";
const instanceFamilyKey = "karpenter.k8s.aws/instance-family";
const minGPUCardsKey = "karpenter.sh/gpu-count";
const minGPUMemoryMiBKey = "karpenter.k8s.aws/instance-gpu-memory";
const defaultSystemDiskSize = 20;
const cpuResourceKey = "cpu";
const memoryResourceKey = "memory";

const extractGeneralNodeProvisionMinCPUCores = (generalNodeProvision: NodePoolSpec | null): number => {
    if (!generalNodeProvision) {
        return 0;
    }
    for (var i = 0; i < generalNodeProvision.template.spec.requirements.length; i++) {
        if (generalNodeProvision.template.spec.requirements[i].key === cpuCoresKey && generalNodeProvision.template.spec.requirements[i].operator ==="Gt") {
            return Number(generalNodeProvision.template.spec.requirements[i].values[0]);
        }
    }
    return 0;
}

const extractGeneralNodeProvisionMaxCPUCores = (generalNodeProvision: NodePoolSpec | null): number => {
    if (!generalNodeProvision) {
        return 0;
    }
    for (var i = 0; i < generalNodeProvision.template.spec.requirements.length; i++) {
        if (generalNodeProvision.template.spec.requirements[i].key === cpuCoresKey && generalNodeProvision.template.spec.requirements[i].operator === "Lt") {
            return Number(generalNodeProvision.template.spec.requirements[i].values[0]);
        }
    }
    return 0;
}

const extractGeneralNodeProvisionMinMemoryMiB = (generalNodeProvision: NodePoolSpec | null): number => {
    if (!generalNodeProvision) {
        return 0;
    }
    for (var i = 0; i < generalNodeProvision.template.spec.requirements.length; i++) {
        if (generalNodeProvision.template.spec.requirements[i].key === memoryMiBKey && generalNodeProvision.template.spec.requirements[i].operator === "Gt") {
            return Number(generalNodeProvision.template.spec.requirements[i].values[0]);
        }
    }
    return 0;
}

const extractGeneralNodeProvisionMaxMemoryMiB = (generalNodeProvision: NodePoolSpec | null): number => {
    if (!generalNodeProvision) {
        return 0;
    }
    for (var i = 0; i < generalNodeProvision.template.spec.requirements.length; i++) {
        if (generalNodeProvision.template.spec.requirements[i].key === memoryMiBKey && generalNodeProvision.template.spec.requirements[i].operator === "Lt") {
            return Number(generalNodeProvision.template.spec.requirements[i].values[0]);
        }
    }
    return 0;
}

const extractGeneralNodeProvisionSystemDiskSizeGiB = (nodeClass: EC2NodeClassSpec | null): number => {
    if (!nodeClass) {
        return defaultSystemDiskSize;
    }

    for (var i = 0; i < nodeClass.blockDeviceMappings?.length!; i++) {
        if (nodeClass.blockDeviceMappings && nodeClass.blockDeviceMappings[i].deviceName === "/dev/xvda") {
            let volumeSize = nodeClass.blockDeviceMappings[i].ebs.volumeSize;
            return Number(volumeSize.split("G")[0]);
        }
    }

    return defaultSystemDiskSize;
}

const extractExtraResourceAllocation = (resourceKey: string, nodePool: NodePoolSpec | null): number => {
    if (!nodePool || !nodePool.template.spec.kubelet) {
        return 0;
    }

    let resource = new Map<string, string>(Object.entries(nodePool.template.spec.kubelet.kubeReserved || {})).get(resourceKey);
    if (!resource) {
        return 0;
    }
    if (resourceKey === cpuResourceKey) {
        return Number(resource?.split("m")[0]);
    }
    
    return Number(resource?.split("M")[0]);
}

const extraNodeProvisionInstanceTags = (nodeClass: EC2NodeClassSpec | null): string => {
    if (!nodeClass) {
        return "";
    }

    let tags = new Map<string, string>(Object.entries(nodeClass.tags || {}));
    let ret = "";
    tags.forEach((value, key) => {
        ret = ret + key + "=" + value + ","
    });
    ret = ret.slice(0, -1);

    return ret;
}

const extractGPUNodeProvisionMinGPUCards = (gpuNodeProvision: NodePoolSpec | null): number => {
    if (!gpuNodeProvision) {
        return 0;
    }
    for (var i = 0; i < gpuNodeProvision.template.spec.requirements.length; i++) {
        if (gpuNodeProvision.template.spec.requirements[i].key === minGPUCardsKey) {
            return Number(gpuNodeProvision.template.spec.requirements[i].values[0]);
        }
    }
    return 0;
}

const extractGPUNodeProvisionMinGPUMemoryMiB = (gpuNodeProvision: NodePoolSpec | null): number => {
    if (!gpuNodeProvision) {
        return 0;
    }
    for (var i = 0; i < gpuNodeProvision.template.spec.requirements.length; i++) {
        if (gpuNodeProvision.template.spec.requirements[i].key === minGPUMemoryMiBKey) {
            return Number(gpuNodeProvision.template.spec.requirements[i].values[0]);
        }
    }
    return 0;
}

const extractNodeProvisionInstanceFamily = (nodeProvision: NodePoolSpec | null): string => {
    if (!nodeProvision) {
        return "";
    }
    for (var i = 0; i < nodeProvision.template.spec.requirements.length; i++) {
        if (nodeProvision.template.spec.requirements[i].key === instanceFamilyKey) {
            let ret = nodeProvision.template.spec.requirements[i].values.reduce((a: string, b: string) => a !== "" ? a + "," + b : b, "");
            // delete , prefix
            return ret;
        }
    }
    return "";
}

const configNodeProvisionMinValues = (minValue: number, key: string, nodePool: NodePoolSpec | null): NodePoolSpec | null => {
    if (!nodePool) {
        console.warn("No general node pool spec found in the cluster");
        return nodePool;
    }

    if (minValue <= 0) {
        for (var i = 0; i < nodePool.template.spec.requirements.length; i++) {
            if (nodePool.template.spec.requirements[i].key === key && nodePool.template.spec.requirements[i].operator === "Gt") {
                nodePool.template.spec.requirements.splice(i, 1);
                return nodePool;
            }
        }
        return nodePool
    }

    for (i = 0; i < nodePool.template.spec.requirements.length; i++) {
        if (nodePool.template.spec.requirements[i].key === key && nodePool.template.spec.requirements[i].operator === "Gt") {
            nodePool.template.spec.requirements[i].values = [ minValue.toString() ];
            return nodePool;
        }
    }

    nodePool.template.spec.requirements.push({
        key: key,
        operator: "Gt",
        values: [ minValue.toString() ]
    });
    return nodePool;
}

const configNodeProvisionMaxValues = (maxValue: number, key: string, nodePool: NodePoolSpec | null): NodePoolSpec | null => {
    if (!nodePool) {
        console.warn("No general node pool spec found in the cluster");
        return nodePool;
    }

    if (maxValue <= 0) {
        for (var i = 0; i < nodePool.template.spec.requirements.length; i++) {
            if (nodePool.template.spec.requirements[i].key === key && nodePool.template.spec.requirements[i].operator === "Lt") {
                nodePool.template.spec.requirements.splice(i, 1);
                return nodePool;
            }
        }
        return nodePool
    }

    for (i = 0; i < nodePool.template.spec.requirements.length; i++) {
        if (nodePool.template.spec.requirements[i].key === key && nodePool.template.spec.requirements[i].operator === "Lt") {
            nodePool.template.spec.requirements[i].values = [ maxValue.toString() ];
            return nodePool;
        }
    }

    nodePool.template.spec.requirements.push({
        key: key,
        operator: "Lt",
        values: [ maxValue.toString() ]
    });
    console.log(nodePool.template.spec.requirements);
    return nodePool;
}

const configNodeProvisionInstanceFamily = (nodeProvisionInstanceFamily: string, nodePool: NodePoolSpec | null): NodePoolSpec | null => {
    if (!nodePool) {
        console.warn("No node pool spec found in the cluster");
        return nodePool;
    }

    if (nodeProvisionInstanceFamily === "") {
        for (var i = 0; i < nodePool.template.spec.requirements.length; i++) {
            if (nodePool.template.spec.requirements[i].key === instanceFamilyKey) {
                nodePool.template.spec.requirements.splice(i, 1);
                return nodePool;
            }
        }
        return nodePool
    }

    let ret = nodeProvisionInstanceFamily.split(",").filter((x: string) => x !== "");
    for (i = 0; i < nodePool.template.spec.requirements.length; i++) {
        if (nodePool.template.spec.requirements[i].key === instanceFamilyKey) {
            nodePool.template.spec.requirements[i].values = ret;
            return nodePool;
        }
    }

    nodePool.template.spec.requirements.push({
        key: instanceFamilyKey,
        operator: "In",
        values: ret
    });
    return nodePool;
}

const configNodeProvisionSystemDiskSize = (nodeProvisionSystemDiskSize: number, ec2NodeClass: EC2NodeClassSpec | null): EC2NodeClassSpec | null => {
    if (!ec2NodeClass) {
        console.warn("No ec2 nodeclass spec found in the cluster");
        return ec2NodeClass;
    }

    if (nodeProvisionSystemDiskSize <= 0) {
        ec2NodeClass.blockDeviceMappings = null;
        return ec2NodeClass;
    }

    ec2NodeClass.blockDeviceMappings = [{
        deviceName: "/dev/xvda",
        ebs: {
            volumeSize: nodeProvisionSystemDiskSize.toString() + "Gi",
            volumeType: "gp3",
            encrypted: true
        }
    }];

    return ec2NodeClass;
}

const configNodeProvisionInstanceTags = (nodeProvisionInstanceTags: string, ec2NodeClass: EC2NodeClassSpec | null): EC2NodeClassSpec | null => {
    if (!ec2NodeClass) {
        console.warn("No ec2 nodeclass spec found in the cluster");
        return ec2NodeClass;
    }

    if (nodeProvisionInstanceTags === "") {
        ec2NodeClass.tags = {};
        return ec2NodeClass
    }

    let tags = new Map<string, string>(Object.entries(ec2NodeClass.tags || {}));
    nodeProvisionInstanceTags.split(",").forEach((x: string) => {
        let kv = x.split("=");
        if (kv.length === 2) {
            tags.set(kv[0].trim(), kv[1].trim());
        }
    });

    ec2NodeClass.tags = Object.fromEntries(tags);
    return ec2NodeClass;
}

const configExtraAllocation = (resourceKey: string, extraAllocation: number, nodePool: NodePoolSpec | null): NodePoolSpec | null => {
    if (!nodePool) {
        console.warn("No node pool spec found in the cluster");
        return nodePool;
    }

    let resourceReservedMap = new Map<string, string>(Object.entries(nodePool.template.spec.kubelet?.kubeReserved || {}));
    if (extraAllocation <= 0) {
        resourceReservedMap.delete(resourceKey);
        let resourceReservedList: {[key: string]: string} = {};
        resourceReservedMap.forEach((value, key) => {
            resourceReservedList[key] = value
        });

        nodePool.template.spec.kubelet = {kubeReserved: resourceReservedList};
        return nodePool
    }
    
    let r = ""
    if (resourceKey === "cpu") {
        r = extraAllocation.toString() + "m";
    } else {
        r = extraAllocation.toString() + "Mi";
    }

    resourceReservedMap.set(resourceKey, r);
    let resourceReservedList: {[key: string]: string} = {};
    resourceReservedMap.forEach((value, key) => {
        resourceReservedList[key] = value
    });

    nodePool.template.spec.kubelet = {kubeReserved: resourceReservedList};
    return nodePool;
}

export {
    isRebalanceComponentReady, configNodeProvisionMinValues, configNodeProvisionInstanceFamily,
    configNodeProvisionSystemDiskSize, configExtraAllocation, configNodeProvisionMaxValues,
    extractNodeProvisionInstanceFamily, extractGPUNodeProvisionMinGPUMemoryMiB, extractGeneralNodeProvisionSystemDiskSizeGiB,
    extractGPUNodeProvisionMinGPUCards, extractExtraResourceAllocation, extractGeneralNodeProvisionMaxMemoryMiB,
    extractGeneralNodeProvisionMinMemoryMiB, extractGeneralNodeProvisionMaxCPUCores, extractGeneralNodeProvisionMinCPUCores,
    configNodeProvisionInstanceTags, extraNodeProvisionInstanceTags };
export { cpuResourceKey, memoryResourceKey, minGPUCardsKey, minGPUMemoryMiBKey, memoryMiBKey, cpuCoresKey };