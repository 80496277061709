import { Response, Request } from "../type/common";
import { userKey } from "../storage";

async function request<T, R>(request: Request<T>): Promise<Response<R>> {
  const authToken = userKey.getToken();

  const response = await fetch(request.path, {
      method: request.method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(request.data),
  });
  if (!response.ok) {
      // Do not reload login page, it's not necessary
      let path = window.location.pathname.replace(/^\//, "").replace(/\/$/, "");
      if (response.status === 401 && path !== "login") {
          console.log(response);
          userKey.clear();
          window.location.reload();
          return {
              code: response.status,
              message: "Unauthorized",
          };
      }
      return {
          code: response.status,
          message: response.statusText,
      };
  }
  const { code, message, data } = await response.json();
  return {
      code: code,
      message: message,
      data: data,
  };
}

async function get<T>(path: string): Promise<Response<T>> {
  return request({ method: "GET", path });
}

async function post<T, R>(path: string, data?: T): Promise<Response<R>> {
  return request({ method: "POST", path, data });
}

async function del<T>(path: string): Promise<Response<T>> {
  return request({ method: "DELETE", path });
}

export const requests = {
  get,
  post,
  del,
};
