import { PageContainer, ProLayout } from "@ant-design/pro-components";
import { homeLayoutProps } from "../config/props";
import { Outlet } from "react-router";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import { userKey } from "../storage";
import { RequireAuth } from "../components/Auth";
import { useAuth0 } from "@auth0/auth0-react";

export default function HomeLayout() {
    const [pathName, setPathName] = useState("/");
    const { logout, user } = useAuth0();
    const [avatarImg, setAvatarImg] = useState(user?.picture);
    const [avatarName, setAvatarName] = useState(user?.nickname);

    useEffect(() => {
      if (!user) {
        return;
      }
      setAvatarImg(user?.picture || "");
      setAvatarName(user?.nickname || "");
    }, [user])

    return (
      <RequireAuth>
        <ProLayout
          siderWidth={216}
          title="CloudPilot AI"
          logo="/logo.png"
          pageTitleRender={(props) => {
            return props.menu?.request?.name || ""
          }}
          avatarProps={{
            src: avatarImg,
            title: avatarName,
            size: "default",
          }}
          location={{pathname: pathName}}
          actionsRender={(props) => {
            return [
              <FontAwesomeIcon icon={faRightFromBracket} onClick={() => {
                userKey.clear();
                logout({logoutParams: {returnTo: window.location.origin}});
              }} color="#1677ff" />
            ];
          }}
          menuItemRender={(item, defaultDom) => (
            <Link to={item.path || '/'} onClick={() => (setPathName(item.path || "/"))}>
              {defaultDom}
            </Link>
          )}
          {...homeLayoutProps}
        >
            <PageContainer>
                <Outlet />
            </PageContainer>
        </ProLayout>
      </RequireAuth>
    );
  }