import { Col, Row, Badge } from "antd";
import { ClusterBilling } from "../type/clusterbilling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

interface CardProps {
    title: string,
    value: number,
    color: string,
    symbol: string,
}

function Card({title, value, color, symbol}: CardProps) {
    let valueSHow = isNaN(value) ? 0 : value;
    return (
        <Badge.Ribbon text={title} className="w-48" color={color}>
        <div className="w-48 flex flex-row pt-10 justify-center">
                <span className="text-[#1677ff] font-bold text-4xl">{symbol}{valueSHow.toFixed(3)}</span>
                <span className="text-[#1677ff] font-bold mt-4">/hour</span>
            </div>
        </Badge.Ribbon>
    )
}

interface SavingsPlanUtilizationHeaderProps {
    clusterBilling: ClusterBilling,
    symbol: string,
}

export default function SavingsPlanUtilizationHeader({ clusterBilling, symbol }: SavingsPlanUtilizationHeaderProps) {
    let totalCommitment: number = 0;
    let commitmentUsage: number = 0;
    if (clusterBilling?.awsBilling?.savingsPlans && clusterBilling?.awsBilling?.savingsPlans.length >= 1) {
        totalCommitment = clusterBilling.awsBilling.savingsPlans.map((savingsPlan) => savingsPlan.commitment).reduce((acc, cur) => Number(acc) + Number(cur), 0.0);
        commitmentUsage = clusterBilling.awsBilling.savingsPlans.map((savingsPlan) => savingsPlan.usage).reduce((acc, cur) => Number(acc) + Number(cur), 0.0);
    }

    let uncoveredOnDemandCost: number = Number(clusterBilling?.awsBilling?.uncoveredOnDemandCost || 0);
    let spotCost: number = Number(clusterBilling?.awsBilling?.spotCost || 0);

    return (
        <div className="flex flex-col bg-white rounded-lg pl-6 pr-6 pb-6 mb-4">
            <div className="flex flex-row justify-between w-full">
                <div className="flex flex-col w-full">
                    <div className="flex flex-row items-center">
                        <h1 className="text-lg font-bold">
                            Current Savings Plan Overview
                        </h1>
                    </div>
                    <Row className="mt-4">
                        <Col flex={3} />
                        <Col flex={1} className="flex flex-col items-center">
                            <Card title="Total Commitment" value={totalCommitment} color="#031012" symbol={symbol} />
                        </Col>
                        <Col flex={1} className="flex flex-col items-center">
                            <Card title="Commitment Usage" value={commitmentUsage} color="#0072e7" symbol={symbol} />
                        </Col>
                        <Col flex={1} className="flex flex-col items-center">
                            <Card title="Uncovered OnDemand Cost" value={uncoveredOnDemandCost} color="#fd4400" symbol={symbol} />
                        </Col>
                        <Col flex={1} className="flex flex-col items-center">
                            <Card title="Spot Cost" value={spotCost} color="#22a1bb" symbol={symbol} />
                        </Col>
                        <Col flex={3} />
                    </Row>
                    <div className="mt-4">
                        <FontAwesomeIcon icon={faCircleInfo} color="#fd4400"/>
                        <span className="text-small ml-2">Currently only visualization is supported, and savings plan will be fully supported soon.</span>
                    </div>
                </div>
            </div>
        </div>
    );
}