import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import en from 'antd/locale/en_US';

import ClustersList from './page/ClusterList';
import HomeLayout from './page/HomeLayout';
import Rebalance from './page/Rebalance';
import ClusterLayout from './page/ClusterLayout';
import AvailableSaving from './page/AvailableSaving';
import Loading from './page/Loading';
import WorkloadConfiguration from './page/WorkloadsConfiguration';
import WorkloadDiag from './page/WorkloadDiag';
import StorageSaving from './page/StorageSaving';
import Commitment from './page/Commitment';
import NotFound from './page/404';
import NodeTemplateConfiguration from './page/NodeTemplateConfiguration';

const App = () => {
  return (
    <div>
      <ConfigProvider locale={en}>
        <Router>
            <Routes>
              <Route path="/" element={<Loading />} />
              <Route path="/clusterlist" element={<HomeLayout />}>
                <Route index element={<ClustersList />} />
                <Route path="/clusterlist" element={<ClustersList />} />
              </Route>
              <Route path="/cluster/:clusterID" element={<ClusterLayout />}>
                <Route index element={<AvailableSaving />} />
                <Route path="computesavings" element={<AvailableSaving />}/>
                <Route path="computerebalance" element={<Rebalance />} />
                <Route path="configuration/workload" element={<WorkloadConfiguration />} />
                <Route path="configuration/nodetemplate" element={<NodeTemplateConfiguration />} />
                <Route path="workloadsdiagnosis" element={<WorkloadDiag />} />
                <Route path="storagesaving" element={<StorageSaving />} />
                <Route path="commitment" element={<Commitment />} />
              </Route>
              <Route path="*" Component={NotFound} />
            </Routes>
        </Router>
      </ConfigProvider>
    </div>
  );
};

export default App;
