import { server } from "../config/props";
import { Response } from "../type/common";
import { requests } from "../utils/fetch";

async function getRebalanceSH(clusterID: string): Promise<Response<string>> {
    return requests.get(`${server}/api/v1/rebalance/clusters/${clusterID}/sh`)
}

export const rebalancesh = {
    getRebalanceSH,
}