import { Space } from "antd";
import { useNavigate, useParams } from "react-router";

import WorkloadsConfigurationList from "../components/WorkloadsConfigurationList";
import { WorkloadConfigurationHeader } from "../components/WorkloadConfigurationHeader";

export default function WorkloadConfiguration() {
    const { clusterID } = useParams();

    const navigate = useNavigate();
    if (!clusterID) {
        navigate("/clusterlist");
        return <></>;
    }

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <div className="w-full h-full">
                <WorkloadConfigurationHeader />
                <WorkloadsConfigurationList clusterID={clusterID} />
            </div>
        </Space>
    )
}