import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SavingsPlanWorks() {
    return (
        <div className="bg-white p-3 rounded-lg mt-4">
            <p className="font-bold text-base mb-4 border-b border-[#f0f0f0] border-[#1px] pb-2">How does CloudPilot AI match your savings plan?</p>
            <div>
                <img src ="/spwork.png" className="w-full" />
            </div>
            <div className="mt-4">
                <FontAwesomeIcon icon={faStar} color="#34cf58"/>
                <span className="text-small ml-2">CloudPilot AI will attempt to use corresponding on-demand instances to fully utilize your Savings Plans commitment. Any additional resource requests will use spot instances.</span>
            </div>
        </div>
    );
}