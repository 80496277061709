import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Button, Progress, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { ClusterRebalanceState } from "../type/rebalance";
import { rebalance } from "../api/rebalance";
import { RefreshTriggerProps } from "../type/common";

export default function RebalaceProcess({ triggerRefresh }: RefreshTriggerProps) {
    const { clusterID } = useParams();

    const [launchReplacementsProcess, setLaunchReplacementsProcess] = useState(1);
    const [drainingNodesProcess, setDrainingNodesProcess] = useState(0);
    const [deleteNodesProcess, setDeleteNodesProcess] = useState(0);
    const [launchReplacementsState, setLaunchReplacementsState] = useState("normal");
    const [drainingNodesState, setDrainingNodesState] = useState("normal");
    const [deleteNodesState, setDeleteNodesState] = useState("normal");

    const isChecking = useRef(false);
    const finished = useRef(false);

    const rebalanceDoing = (
        <Spin key="checking" size="large" indicator={<LoadingOutlined spin />} />
    )
    const tryAgain = (
        <Button key="wrong" type="primary" onClick={() => {
            triggerRefresh();
        }}>
            Something wrong and click to try again
        </Button>
    )
    const OK = (
        <Button key="ok" type="primary" className="w-64" onClick={() => {
            triggerRefresh();
            return;
        }}>
            OK
        </Button>
    )
    const [rebalanceButton, setRebalanceButton] = useState(rebalanceDoing)

    useEffect(() => {
        if (clusterID === "") {
            return;
        }
        async function fetchClusterRebalanceConfiguration() {
            if (!clusterID) {
                return;
            }
            const { code, message, data } =
                await rebalance.getRebalanceConfiguration(clusterID);
            if (code !== 200) {
                console.error("Failed to fetch cluster rebalance status:", message);
                return;
            }
            if (!data?.enable) {
                triggerRefresh();
                return
            }
        }
        fetchClusterRebalanceConfiguration();
    }, [clusterID])

    useEffect(() => {
        if (isChecking.current && finished.current) {
            // If stateChecking is already running, don't start it again
            return;
        }
        isChecking.current = true;

        async function stateChecking() {
            while (isChecking.current) {
                if (!clusterID) {
                    return;
                }

                await new Promise(resolve => setTimeout(resolve, 3000));

                let state = ClusterRebalanceState.ClusterRebalanceStateApplying;
                const { code, data } = await rebalance.getRebalanceStatus(clusterID);
                if (code !== 200) {
                    state = ClusterRebalanceState.ClusterRebalanceStateApplying;
                } else {
                    state = data?.state || ClusterRebalanceState.ClusterRebalanceStateApplying;
                }
                switch (state) {
                    case ClusterRebalanceState.ClusterRebalanceStateLaunchingReplacements:
                    case ClusterRebalanceState.ClusterRebalanceStateApplying:
                        setDrainingNodesProcess(0);
                        setDeleteNodesProcess(0);
                        setLaunchReplacementsProcess(prevProcess => {
                            if (prevProcess + 3 >= 100) {
                                return 99;
                            } else {
                                return prevProcess + 3;
                            }
                        })
                        break;
                    case ClusterRebalanceState.ClusterRebalanceStateDraining:
                        setLaunchReplacementsProcess(100);
                        setLaunchReplacementsState("success")
                        setDeleteNodesProcess(0);
                        setDrainingNodesProcess(prevProcess => {
                            if (prevProcess + 3 >= 100) {
                                return 99;
                            } else {
                                return prevProcess + 3;
                            }
                        });
                        break;
                    case ClusterRebalanceState.ClusterRebalanceStateTerminating:
                        setLaunchReplacementsProcess(100);
                        setLaunchReplacementsState("success")
                        setDrainingNodesProcess(100);
                        setDrainingNodesState("success");
                        setDeleteNodesProcess(prevProcess => {
                            if (prevProcess + 3 >= 100) {
                                return 99;
                            } else {
                                return prevProcess + 3;
                            }
                        });
                        break;
                    case ClusterRebalanceState.ClusterRebalanceStateFailed:
                        finished.current = true;
                        setLaunchReplacementsProcess(prevProcess => {
                            if (prevProcess < 100) {
                                setLaunchReplacementsState("exception");
                            }
                            return prevProcess;
                        })
                        setDrainingNodesProcess(prevProcess => {
                            if (prevProcess < 100) {
                                setDrainingNodesState("exception");
                            }
                            return prevProcess;
                        })
                        setDeleteNodesProcess(prevProcess => {
                            if (prevProcess < 100) {
                                setDeleteNodesState("exception");
                            }
                            return prevProcess;
                        })
                        setRebalanceButton(tryAgain);
                        return
                    case ClusterRebalanceState.ClusterRebalanceStateSuccess:
                        finished.current = true;
                        setLaunchReplacementsProcess(100);
                        setLaunchReplacementsState("success")
                        setDrainingNodesProcess(100);
                        setDrainingNodesState("success")
                        setDeleteNodesProcess(100);
                        setDeleteNodesState("success");
                        setRebalanceButton(OK);
                        return;
                }
            }
        }
        stateChecking();

        // Cleanup function
        return () => {
            isChecking.current = false;
        };
    }, [clusterID])

    return (
        <div className="w-full h-full mt-10 flex justify-center items-center">
            <div className="flex flex-col bg-white rounded-lg p-6">
                <div className="flex flex-row w-full justify-center items-center mb-10">
                    <span className="text-lg mt-10 w-2/4 text-center font-bold">CloudPilot AI starts rebalancing...</span>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                    <div className="flex flex-col items-center justify-center min-w-36">
                        <Progress
                            type="circle"
                            percent={launchReplacementsProcess}
                            status={launchReplacementsState as "normal" | "success" | "exception" | "active" | undefined}
                            format={(percent) => {
                                return (
                                    <>
                                        <p className="m-0 p-0 text-sky-600">{percent}%</p>
                                        <p className="text-base p-0 text-wrap">
                                            Launch replacements
                                        </p>
                                    </>
                                )
                            }}
                            size={200}
                        />
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} size="2x" color="#2564eb"/>
                    <div className="flex flex-col items-center justify-center min-w-36">
                        <Progress
                            type="circle"
                            percent={drainingNodesProcess}
                            status={drainingNodesState as "normal" | "success" | "exception" | "active" | undefined}
                            format={(percent) => {
                                return (
                                    <>
                                        <p className="m-0 p-0 text-sky-600">{percent}%</p>
                                        <p className="text-base p-0 text-wrap">
                                            Draining nodes
                                        </p>
                                    </>
                                )
                            }}
                            size={200}
                        />
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} size="2x" color="#2564eb"/>
                    <div className="flex flex-col items-center justify-center min-w-36">
                        <Progress
                            type="circle"
                            percent={deleteNodesProcess}
                            status={deleteNodesState as "normal" | "success" | "exception" | "active" | undefined}
                            format={(percent) => {
                                return (
                                    <>
                                        <p className="m-0 p-0 text-sky-600">{percent}%</p>
                                        <p className="text-base p-0 text-wrap">
                                            Delete nodes
                                        </p>
                                    </>
                                )
                            }}
                            size={200}
                        />
                    </div>
                </div>
                <div className="flex flex-row w-full justify-center items-center">
                    <span className="text-lg mt-10 w-2/4 text-center">Once this rebalancing process is complete, <b>CloudPilot AI</b> will ensure your cluster maintains high availability while also providing the best price!</span>
                </div>
                <div className="flex flex-row w-full justify-center items-center mt-10">
                    {rebalanceButton}
                </div>
            </div>
        </div>
    )
}