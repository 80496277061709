interface ClusterCostsSummary {
    id: string;
    demo: boolean;
    clusterName: string;
    cloudProvider: string;
    region: string;
    initialMonthlyCost: number;
    initialOptimizedMonthlyCost: number;
    monthlyCost: number;
    estimateMonthlySaving: number;
    nodesNumber: number;
    onDemandNodesNumber: number;
    spotNodesNumber: number;
    cpuCores: number;
    ramGiBs: number;
    gpuCards: number;
    status: ClusterStatus;
}

enum ClusterStatus {
    ClusterStatusOnline = "online",
    ClusterStatusOffline = "offline",
}

export type { ClusterCostsSummary };
export { ClusterStatus };