import { server } from "../config/props";
import { ClusterCostOptimization } from "../type/clustercostoptimization";
import { Response } from "../type/common";
import { requests } from "../utils/fetch";

async function getClusterCostOptimization(clusterID: string): Promise<Response<ClusterCostOptimization>> {
    return requests.get(`${server}/api/v1/costs/clusters/${clusterID}/optimization`);
}

export const clustercostoptimization = {
    getClusterCostOptimization,
}