import { faChevronCircleRight, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";

export function WorkloadConfigurationHeader() {
    const tipText = "Only work for kubernetes v1.22+";
    return (
        <div className="flex flex-col bg-white rounded-lg p-6 mb-4">
            <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row space-x-8">
                    <div className="flex flex-col ">
                        <div className="flex flex-row items-center">
                            <h1 className="text-lg font-bold">Workload Configuration</h1>
                            <Tooltip placement="top" title={tipText}>
                                <FontAwesomeIcon icon={faCircleInfo} size="lg" className="ml-2"/>                    
                            </Tooltip>
                        </div>
                        <p><FontAwesomeIcon icon={faChevronCircleRight} /> Configure your specific workload to ensure non-interruption.</p>
                        <p><FontAwesomeIcon icon={faChevronCircleRight} /> Please wait at least 30 seconds until the configuration is applied.</p>
                        <p><FontAwesomeIcon icon={faChevronCircleRight} /> All modifications will be performed during the next rebalance operations.</p>
                        <p><FontAwesomeIcon icon={faChevronCircleRight} /> Only StatefulSet/Deployment workloads are supported for configuration.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}