import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { Button, Input, Modal, Select, Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAws } from "@fortawesome/free-brands-svg-icons";
import { faCloud, faLocationDot, faPlus } from "@fortawesome/free-solid-svg-icons";

import { ClusterCostsSummary, ClusterStatus } from "../type/clustercostssummary";
import { agentsh } from "../api/agentsh"
import CodeBlock from "./CodeBlack";
import { cluster } from "../api/cluster";
import { demo } from "../api/demo";
import CloudPilotSpin from "./Spin";
import { clustercostssummary } from "../api/clustercostssummary";
import { rebalance } from "../api/rebalance";
import { getCostSymbol } from "../utils/getsymbol";

const baseColumns: ProColumns<ClusterCostsSummary>[] = [
  {
    title: "Cloud Provider",
    dataIndex: "cloudProvider",
    key: "cloudprovider",
    copyable: false,
    search: false,
    render: (text, record, _, action) => {
      return <FontAwesomeIcon icon={faAws} key="cloudprovider" className="w-10 h-5" />
    },
  },
  {
    disable: true,
    title: "Region",
    key: "region",
    dataIndex: "region",
    search: false,
  },
  {
    disable: true,
    title: "Monthly Cost",
    key: "monthlycost",
    dataIndex: "monthlyCost",
    sorter: (a, b) => a.nodesNumber - b.nodesNumber,
    search: false,
    render: (text, record, _, action) => [
      <span key="monthlycost">{getCostSymbol(record.region)}{(text as number).toFixed(3)}</span>
    ],
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, _) => a.status === ClusterStatus.ClusterStatusOnline ? 1 : -1,
    hideInSearch: true,
    render: (text, record, _, action) => {
      return text === ClusterStatus.ClusterStatusOffline ?
        <div className="flex items-center space-x-2" key="statusOffline">
          <span className="bg-red-600 rounded-full w-4 h-4 inline-block"></span>
          <span>{text}</span>
        </div> :
        <div className="flex items-center space-x-2" key="statusOnline">
          <span className="bg-green-500 rounded-full w-4 h-4 inline-block"></span>
          <span>{text}</span>
        </div>
    },
  },
  {
    title: "Nodes",
    dataIndex: "nodesNumber",
    key: "nodesNumber",
    sorter: (a, b) => a.nodesNumber - b.nodesNumber,
    hideInSearch: true,
  },
  {
    title: "Cluster ID",
    dataIndex: "id",
    key: "clusterid",
    sorter: false,
    hideInSearch: true,
  },
]

interface ConnectClusterModalProps {
  addClusterModal: boolean;
  onCancel: () => void;
  footer: React.ReactNode;
  agentSH: string;
}

function ConnectClusterModal({ addClusterModal, onCancel, footer, agentSH }: ConnectClusterModalProps) {
  return (
    <Modal
        title="Connect your kubernetes cluster"
        open={addClusterModal}
        onCancel={onCancel}
        footer={footer}>
        <p className="font-bold mb-2">Open your terminal and run the following command</p>
        <p className="mb-2">Please make sure kubectl is installed before running the command.</p>
        <CodeBlock code={agentSH} />
        <p className="mt-2">
          <span className="font-bold">CloudPilot AI</span>'s read-only agent only has the access to your cloud resource data, and won't change your cluster configuration.
        </p>
      </Modal>
  )
}

type DisableRebalanceLinkProps = {
  clusterID: string;
};

function DisableRebalanceLink({ clusterID }: DisableRebalanceLinkProps) {
  const [rebalanceEnabled, setRebalanceEnabled] = useState(false);

  useEffect(() => {
    async function fetchClusterRebalanceConfiguration() {
      const cfg = await rebalance.getRebalanceConfiguration(clusterID);
      if (cfg.code !== 200) {
        console.error("Failed to fetch cluster rebalance configuration:", cfg.message)
        return;
      }
      setRebalanceEnabled(cfg.data?.enable || false);
    }
    fetchClusterRebalanceConfiguration();
  }, [clusterID])

  function handleRebalanceSwitch(checked: boolean, _: any) {
      setRebalanceEnabled(checked);
      if (checked) {
          rebalance.updateRebalanceConfiguration(clusterID, { enable: true, ec2NodeClassSpec: null, generalNodePoolSpec: null, gpuNodePoolSpec: null });
          return
      }
      rebalance.updateRebalanceConfiguration(clusterID, { enable: false, ec2NodeClassSpec: null, generalNodePoolSpec: null, gpuNodePoolSpec: null });
  }

  return (
    <div className="flex flex-row w-full items-center mb-2 mt-4">
      <label className="min-w-48 font-bold mr-4">1.Disable CloudPilot AI rebalance:</label>
      <Switch checked={rebalanceEnabled} onClick={handleRebalanceSwitch} defaultChecked />
    </div>
  );
}

interface RemoveClusterModalProps {
  removeClusterModal: boolean;
  onCancel: () => void;
  footer: React.ReactNode;
  clusterID: string;
}

function RemoveClusterModal({removeClusterModal, onCancel, footer, clusterID}: RemoveClusterModalProps) {
  const [uninstallSH, setUninstallSH] = useState("");
  const [restoreSH, setRestoreSH] = useState("");
  useEffect(() => {
    async function fetchUninstallSH() {
        if (clusterID === "") {
          return;
        }
        const uninstallData = await cluster.getUninstallSH(clusterID)
        if (uninstallData.code !== 200) {
            console.error("Failed to fetch uninstall sh:", uninstallData.message);
            return;
        }
        setUninstallSH(uninstallData.data!);

        const restoreData = await cluster.getRestoreSH(clusterID)
        if (restoreData.code !== 200) {
            console.error("Failed to fetch restore sh:", restoreData.message);
            return;
        }
        setRestoreSH(restoreData.data!);
    }
    fetchUninstallSH();
  }, [clusterID]);

  const drainNodes = "kubectl get node -l node.cloudpilot.ai/managed=true | tail -n +2 | awk '{print $1}' | xargs kubectl drain --ignore-daemonsets  --delete-emptydir-data --force";
  const waitNodesRemoved = "while [[ $(kubectl get nodes -l node.cloudpilot.ai/managed=true -o json | jq -r '.items | length') -ne 0 ]]; do echo \"Waiting for CloudPilot AI nodes to be removed...\"; sleep 3; done";
  return (
    <Modal
      title="Remove kubernetes cluster from CloudPilot AI"
      open={removeClusterModal}
      onCancel={onCancel}
      footer={footer}>
        <DisableRebalanceLink clusterID={clusterID} />
        <p className="font-bold mb-2">2.Modify your managed node group to the correct desired size:</p>
        <CodeBlock code={restoreSH} />
        <p className="font-bold mb-2">3.Delete the node created by CloudPilt AI:</p>
        <CodeBlock code={drainNodes} />
        <p className="font-bold mb-2">4.Wait the nodes created by CloudPilot AI are removed:</p>
        <CodeBlock code={waitNodesRemoved} />
        <p className="font-bold mb-2">5.Uninstal CloudPilot AI:</p>
        <CodeBlock code={uninstallSH} />
        <p className="mt-2">
          All the CloudPilot AI components will be removed from your kubernetes cluster.
        </p>
    </Modal>
  )
}

async function waitClusterAdd(summaries: ClusterCostsSummary[]) {
  const startTime = Date.now();
  while (Date.now() - startTime < 60000) {
    const { code, message, data } = await clustercostssummary.listAllClusterCostsSummary();
    if (code !== 200) {
      console.log("Failed to fetch clusters cost summary:", message);
      break;
    }

    if (data === undefined || data === null) {
      // wait 1s
      await new Promise((resolve) => setTimeout(resolve, 1000));
      continue;
    }

    let costReady = true;
    for (const summary of data) {
      if (summary.initialMonthlyCost === 0.0) {
        costReady = false;
      }
    }
    if (costReady && data.length > summaries.length) {
      break;
    }
    // wait 1s
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
}

async function waitClusterDisappear(summaries: ClusterCostsSummary[]) {
  const startTime = Date.now();
  while (Date.now() - startTime < 60000) {
    const { code, message, data } = await clustercostssummary.listAllClusterCostsSummary();
    if (code !== 200) {
      console.log("Failed to fetch clusters cost summary:", message);
      break;
    }
    if (data !== undefined) {
      if (data === null && summaries.length === 1) {
        break;
      }
      if (data !== null && data.length < summaries.length) {
        break;
      }
    }
    // wait 1s
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
}

interface CostOverviewCardProps {
  summaries: ClusterCostsSummary[];
}

export default function ClustersList({summaries}: CostOverviewCardProps) {
  const actionRef = useRef<ActionType>();

  const [addClusterModal, setAddClusterModal] = useState(false);
  const [addDemoClusterModal, setAddDemoClusterModal] = useState(false);
  const [removeClusterModal, setRemoveClusterModal] = useState(false);
  const [removeClusterID, setRemoveClusterID] = useState("");

  const [agentSH, setAgentSH] = useState("");
  useEffect(() => {
    async function fetchAgentSH() {
        const { code, message, data } =
            await agentsh.getAgentSH();
        if (code !== 200) {
            console.error("Failed to fetch agent sh:", message);
            return;
        }
        setAgentSH(data!);
    }
    fetchAgentSH();
  }, []);

  const navigate = useNavigate();
  const columns: ProColumns<ClusterCostsSummary>[] = [
    {
      title: "Cluster Name",
      dataIndex: "clusterName",
      key: "clustername",
      copyable: false,
      search: true,
      render: (text, record, _, action) => {
        return <a className="text-blue-800" key="clustername" onClick={() => {
          navigate("/cluster/" + record.id + "/computesavings");
        }}>
          {text}
        </a>
      },
    },
    ...baseColumns,
    {
      title: "Operation",
      valueType: "text",
      key: "operation",
      search: false,
      render: (text, record, _, action) => [
        <a className="text-blue-900" key="operation" onClick={async () => {
          setRemoveClusterID(record.id);
          setRemoveClusterModal(true);
        }}>
          Remove
        </a>,
      ]
    },
  ];

  const ranScriptButton = (
      <Button key="ranScript" type="primary" onClick={async () => {
        setAddClusterState(<CloudPilotSpin />);
        await waitClusterAdd(summaries);
        window.location.reload();
      }}>
          I ran the script
      </Button>
  );
  const [addClusterState, setAddClusterState] = useState(ranScriptButton);

  const [demoClusterName, setDemoClusterName] = useState("cloudpilot-cluster-demo");
  const [demoClusterRegion, setDemoClusterRegion] = useState("us-east-2");
  const [addDemoClusterState, setAddDemoClusterState] = useState(<></>);

  const demoClusterRegionRef = useRef(demoClusterRegion);
  demoClusterRegionRef.current = demoClusterRegion;

  const addDemoCluster = (
    <Button key="addDemoCluster" type="primary" onClick={async () => {
      setAddDemoClusterState(<CloudPilotSpin />);
      await demo.createDemoCluster(demoClusterName, demoClusterRegionRef.current);
      await waitClusterAdd(summaries);
      window.location.reload();
    }}>
        Add
    </Button>
  );

  useEffect(() => {
    setAddDemoClusterState(addDemoCluster);
  }, [demoClusterName])

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDemoClusterName(event.target.value);
  }

  useEffect(() => {
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, [summaries])

  const removeClusterButton = (
    <Button type="primary" key="removeCluster" onClick={async () => {
      setRemoveClusterFooter(<CloudPilotSpin />);
      await cluster.unregisterCluster(removeClusterID);
      await waitClusterDisappear(summaries);
      window.location.reload();
    }}>
       I ran the script
    </Button>
  );
  useEffect(() => {
    setRemoveClusterFooter(removeClusterButton);
  }, [removeClusterID])
  const [removeClusterFooter, setRemoveClusterFooter] = useState(removeClusterButton);

  return (
    <div>
      <ConnectClusterModal
        addClusterModal={addClusterModal}
        onCancel={() => {setAddClusterModal(false)}}
        footer={addClusterState}
        agentSH={agentSH}
      />
      <RemoveClusterModal
        removeClusterModal={removeClusterModal}
        onCancel={() => {setRemoveClusterModal(false)}}
        footer={removeClusterFooter}
        clusterID={removeClusterID}
      />
      <Modal
        title="Add demo cluster"
        open={addDemoClusterModal}
        onCancel={() => {
          setAddDemoClusterModal(false);
          setAddDemoClusterState(addDemoCluster);
        }}
        footer={addDemoClusterState}
      >
        <div>
          <span>Input demo cluster name and region:</span>
          <div className="flex flex-row">
            <Input
              size="large"
              className="w-4/6 h-10"
              placeholder="demo cluster name"
              prefix={<FontAwesomeIcon icon={faCloud} style={{ color: "#1677ff" }} />}
              value={demoClusterName}
              onChange={handleInputChange}
            />
            <div className="w-1" />
            <Select
              defaultValue="us-east-2"
              className="w-2/6 h-10"
              suffixIcon={<FontAwesomeIcon icon={faLocationDot} style={{ color: "#1677ff" }} />}
              onChange={(value) => setDemoClusterRegion(value)}
              options={[
                { value: 'us-east-2', label: 'us-east-2' },
                { value: 'cn-north-1', label: 'cn-north-1' },
              ]}
            />
          </div>
        </div>
      </Modal>
      <ProTable<ClusterCostsSummary>
        actionRef={actionRef}
        cardBordered
        request={async (params, sort, filter) => {
          let data = summaries;
          if (params.clustername) {
            data = summaries.filter((summary) => summary.clusterName.startsWith(params.clustername));
          }
          return { data: data, success: true };
        }}
        rowKey="id"
        columns={columns}
        search={{
          labelWidth: "auto",
        }}
        options={false}
        pagination={{
          pageSize: 10,
          onChange: (page) => console.log(page),
        }}
        dateFormatter="string"
        headerTitle="Clusters List"
        toolBarRender={() => [
          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              setAddClusterModal(true);
            }}
            type="primary"
          >
            Add Cluster
          </Button>,
          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              setAddDemoClusterModal(true);
            }}
            type="primary"
          >
            Add Demo Cluster
          </Button>,
        ]}
      />
    </div>
  );
};
