import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

function RequireAuth({ children }: { children: JSX.Element }) {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  return children;
}

export { RequireAuth };