import { Response } from "../type/common";
import { requests } from "../utils/fetch";
import { DemoCluster } from "../type/democluster";
import { server } from "../config/props";

async function createDemoCluster(clusterName: string, region: string): Promise<Response<string>> {
    const demoCluster: DemoCluster = { name: clusterName, region: region };
    return requests.post(
        `${server}/api/v1/demo/clusters`,
        demoCluster
    );
}

async function enableDemoClusterRebalanceController(clusterID: string): Promise<Response<string>> {
    return requests.get(
        `${server}/api/v1/demo/clusters/${clusterID}/rebalance`,
    );
}


export const demo = {
    createDemoCluster,
    enableDemoClusterRebalanceController,
}