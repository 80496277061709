import { faCircleNodes, faFaceSmileWink, faMemory, faMicrochip, faServer } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { clustercostssummary } from "../api/clustercostssummary";
import { ClusterCostsSummary } from "../type/clustercostssummary";
import { ClusterCostOptimization } from "../type/clustercostoptimization";
import { clustercostoptimization } from "../api/clustercostoptimization";
import { ClusterConfigurationSimple } from "../components/ClusterConfiguration";
import { ClusterRebalanceConfiguration, ClusterRebalanceState, ClusterRebalanceStatus } from "../type/rebalance";
import { rebalance } from "../api/rebalance";
import { loading } from "../components/Loading";
import { Button } from "antd";
import { getCostSymbol } from "../utils/getsymbol";

export default function ClusterState() {
    const { clusterID } = useParams();

    const [clusterSummary, setClusterSummary] = useState<ClusterCostsSummary>();
    const [clusterOptimization, setclusterOptimization] = useState<ClusterCostOptimization>();
    const [savingBy, setSavingBy] = useState(0);
    const [rebalanceStatus, setRebalanceStatus] = useState<ClusterRebalanceStatus>();
    const [rebalanceCfg, setRebalanceCfg] = useState<ClusterRebalanceConfiguration>();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchClusterRebalance() {
            if (!clusterID) {
                return;
            }
            const status = await rebalance.getRebalanceStatus(clusterID);
            if (status.code !== 200) {
                console.error("Failed to fetch cluster rebalance status:", status.message);
                return;
            }
            setRebalanceStatus(status.data!);

            const cfg = await rebalance.getRebalanceConfiguration(clusterID);
            if (cfg.code !== 200) {
                console.error("Failed to fetch cluster rebalance status:", cfg.message);
                return;
            }
            setRebalanceCfg(cfg.data!);
        }
        fetchClusterRebalance();
    }, [clusterID])

    useEffect(() => {
        async function fetchClusterCostsSummary() {
            if (!clusterID) {
                return;
            }
            const { code, message, data } =
                await clustercostssummary.getClusterCostsSummary(clusterID);
            if (code !== 200) {
                console.error("Failed to fetch cluster costs summary:", message);
                return;
            }
            setClusterSummary(data!);
            if (data) {
                const savingByCurrent = Math.floor((1-(data.initialOptimizedMonthlyCost / data.initialMonthlyCost)) * 100);
                setSavingBy(savingByCurrent);
                if ((100 - savingByCurrent) <= 0.000001 ) {
                    setSavingBy(0);
                }
            }
        }
        fetchClusterCostsSummary();
    }, [clusterID])

    useEffect(() => {
        async function fetchClusterCostOptimization() {
            if (!clusterID) {
                return;
            }
            const { code, message, data } =
                await clustercostoptimization.getClusterCostOptimization(clusterID);
            if (code !== 200) {
                console.error("Failed to fetch cluster costs optimization:", message);
                return;
            }
            setclusterOptimization(data!);
        }
        fetchClusterCostOptimization();
    }, [clusterID])

    if (!rebalanceStatus || !rebalanceCfg || !clusterSummary || !clusterOptimization) {
        return <loading.RequestLoading />;
    }
    const symbol = getCostSymbol(clusterSummary.region);

    if (!rebalanceCfg.enable || rebalanceStatus.state !== ClusterRebalanceState.ClusterRebalanceStateSuccess) {
        return (
            <div className="flex flex-col bg-white rounded-lg p-6 mt-40 pt-36">
                <div className="flex flex-row w-full justify-center items-center mb-10">
                    <img alt="logo" className="w-24 h-24 object-center mx-auto" src="/logo.png" />
                </div>
                <div className="flex flex-row w-full justify-center items-center mb-10">
                    <span className="text-lg">
                        <b>CloudPilot AI </b>
                        is reay to optimize your clusters!
                    </span>
                </div>
                <div className="flex flex-row w-full justify-center items-center mb-10">
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate(`/cluster/${clusterID}/computerebalance`)
                        }}
                    >
                        Go To Rebalance
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full h-full">
            <div className="flex flex-col bg-white rounded-lg p-6">
                <div className="flex flex-row justify-between w-full">
                    <div className="flex flex-row space-x-8">
                        <div className="flex flex-col">
                            <FontAwesomeIcon size="5x" icon={faFaceSmileWink} color="#22c55e" />
                        </div>
                        <div className="flex flex-col space-y-4">
                            <h1 className="text-lg font-bold">
                                Well done, your cluster now runs in the most cost-effective way!
                            </h1>
                            <span><b>CloudPilot AI</b> will continually optimiza your cluster.</span>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-8">
                        <div className="flex flex-row space-x-8">
                            <div className="flex flex-col space-y-4">
                                <span>Current Monthly Cost</span>
                                <h1 className="text-lg font-bold">{symbol + clusterSummary?.monthlyCost.toFixed(3)}</h1>
                            </div>
                        </div>
                        <div className="flex flex-row space-x-8">
                            <div className="flex flex-col space-y-4">
                                <span>Saving by</span>
                                <h1 className="text-lg font-bold">{savingBy}%</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col bg-white rounded-lg mt-10">
                <div className="flex flex-row justify-between w-full border-b-[1px] p-6">
                    <div className="flex flex-row space-x-8">
                        <span className="text-lg font-bold">Cluster Overview</span>
                    </div>
                </div>
                <div className="flex flex-row justify-between w-full border-b-[1px]">
                    <div className="flex flex-col space-x-3 border-r-[1px] border-b-[1px] w-1/3">
                        <div className="flex flex-row pt-3 pl-3">
                            <FontAwesomeIcon size="xl" icon={faCircleNodes} color="#0284c7" />
                            <label className="text-normal font-semibold ml-4">Node</label>
                        </div>
                        <div className="w-full flex flex-col justify-between items-center">
                            <span className="font-bold text-4xl pb-6 pt-4">{clusterSummary?.nodesNumber}</span>
                        </div>
                    </div>
                    <div className="flex flex-col space-x-3 border-r-[1px] border-b-[1px] w-1/3">
                        <div className="flex flex-row pt-3 pl-3">
                            <FontAwesomeIcon size="xl" icon={faServer} color="#16a34a" />
                            <label className="text-normal font-semibold ml-4">Spot</label>
                        </div>
                        <div className="w-full flex flex-col justify-between items-center">
                            <span className="font-bold text-4xl pb-6 pt-4">
                                {(clusterSummary?.nodesNumber || 0) - (clusterSummary?.onDemandNodesNumber || 0)}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col space-x-3 border-b-[1px] w-1/3">
                        <div className="flex flex-row pt-3 pl-3">
                            <FontAwesomeIcon size="xl" icon={faServer} color="#dc2626" />
                            <label className="text-normal font-semibold ml-4">On Demand</label>
                        </div>
                        <div className="w-full flex flex-col justify-between items-center">
                            <span className="font-bold text-4xl pb-6 pt-4">{clusterSummary?.onDemandNodesNumber}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between w-full border-b-[1px]">
                    <div className="flex flex-col space-x-3 border-r-[1px] border-b-[1px] w-1/3">
                        <div className="flex flex-row pt-3 pl-3">
                            <FontAwesomeIcon size="xl" icon={faMicrochip} color="#0284c7" />
                            <label className="text-normal font-semibold ml-4">vCPU(Core)</label>
                        </div>
                        <div className="w-full flex flex-col justify-between items-center">
                            <span className="font-bold text-4xl pb-6 pt-4">{clusterSummary?.cpuCores}</span>
                        </div>
                    </div>
                    <div className="flex flex-col space-x-3 border-r-[1px] border-b-[1px] w-1/3">
                        <div className="flex flex-row pt-3 pl-3">
                            <FontAwesomeIcon size="xl" icon={faMemory} color="#0284c7" />
                            <label className="text-normal font-semibold ml-4">Memory(GiB)</label>
                        </div>
                        <div className="w-full flex flex-col justify-between items-center">
                            <span className="font-bold text-4xl pb-6 pt-4">{clusterSummary?.ramGiBs}</span>
                        </div>
                    </div>
                    <div className="flex flex-col space-x-3 border-b-[1px] w-1/3">
                        <div className="flex flex-row pt-3 pl-3">
                            <img alt="gpu" src="/icon/gpu.svg" className="w-6" />
                            <label className="text-normal font-semibold ml-4">GPU(Card)</label>
                        </div>
                        <div className="w-full flex flex-col justify-between items-center">
                            <span className="font-bold text-4xl pb-6 pt-4">{clusterSummary?.gpuCards}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col justify-between bg-white mt-10">
                <ClusterConfigurationSimple
                    title="Current cluster configuration"
                    symbol={symbol}
                    nodesConfiguration={clusterOptimization?.currentNodes || []}
                />
            </div>
        </div>
    )
}