import { useEffect, useState } from "react";
import { Button, Modal, Progress, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router";

import { rebalancesh } from "../api/rebalancesh";
import { ClusterCostsSummary } from "../type/clustercostssummary";
import { clustercostssummary } from "../api/clustercostssummary";
import { ClusterCostOptimization } from "../type/clustercostoptimization";
import { clustercostoptimization } from "../api/clustercostoptimization";
import { ClusterConfiguration } from "../components/ClusterConfiguration";
import CodeBlock from "../components/CodeBlack";
import { rebalance } from "../api/rebalance";
import { demo } from "../api/demo";
import { isRebalanceComponentReady } from "../utils/rebalance";
import { loading } from "../components/Loading";
import CloudPilotSpin from "../components/Spin";
import { getCostSymbol } from "../utils/getsymbol";

export default function ClusterAvailableSaving() {
  const [clusterSummary, setClusterSummary] = useState<ClusterCostsSummary>();
  const [clusterOptimization, setclusterOptimization] =
    useState<ClusterCostOptimization>();
  const [addRebalance, setAddRebalance] = useState(false);
  const [rebalanceSH, setRebalanceSH] = useState("");
  const { clusterID } = useParams();

  const navigate = useNavigate();
  const readyToRebalance = (
    <div>
      <Button
        className="mr-4"
        key="configure"
        type="primary"
        onClick={() => {
          navigate(`/cluster/${clusterID}/configuration/workload`);
        }}>
        Configure before rebalancing
      </Button>
      <Button
        key="readyToRebalance"
        type="primary"
        onClick={() => {
          navigate(`/cluster/${clusterID}/computerebalance`);
        }}
      >
        Start rebalance cluster
      </Button>
    </div>
  );

  useEffect(() => {
    async function fetchClusterCostsSummary() {
      if (!clusterID) {
        return;
      }
      const { code, message, data } =
        await clustercostssummary.getClusterCostsSummary(clusterID);
      if (code !== 200) {
        console.error("Failed to fetch cluster costs summary:", message);
        return;
      }
      setClusterSummary(data!);
    }
    fetchClusterCostsSummary();
  }, [clusterID]);

  useEffect(() => {
    async function fetchClusterCostOptimization() {
      if (!clusterID) {
        return;
      }
      const { code, message, data } =
        await clustercostoptimization.getClusterCostOptimization(clusterID);
      if (code !== 200) {
        console.error("Failed to fetch cluster costs optimization:", message);
        return;
      }
      setclusterOptimization(data!);
    }
    fetchClusterCostOptimization();
  }, []);

  useEffect(() => {
    async function fetchRebalanceSH() {
      if (!clusterID) {
        return;
      }
      const { code, message, data } = await rebalancesh.getRebalanceSH(clusterID);
      if (code !== 200) {
        console.error("Failed to fetch rebalance.sh:", message);
        return;
      }
      setRebalanceSH(data!);
    }
    fetchRebalanceSH();
  }, [clusterID]);

  const tryAgain = (
    <Button
      key="wrong"
      type="primary"
      onClick={() => {
        handleRanScript();
      }}
    >
      Something wrong and please try again
    </Button>
  );
  const ranScriptButton = (
    <Button
      key="start"
      type="primary"
      onClick={() => {
        handleRanScript();
      }}
    >
      I ran the script
    </Button>
  );

  useEffect(() => {
    setRebalanceState(ranScriptButton);
  }, [clusterSummary]);
  const [rebalanceState, setRebalanceState] = useState(<></>);

  if (!clusterSummary || !clusterOptimization || !clusterOptimization.currentNodes) {
    return <loading.RequestLoading />;
  }

  async function handleRanScript() {
    if (!clusterSummary || !clusterID) {
      return;
    }
    setRebalanceState(CloudPilotSpin);
    if (clusterSummary?.demo) {
      await demo.enableDemoClusterRebalanceController(clusterSummary.id);
    }

    let componentOK = false;
    const startTime = Date.now();
    while (Date.now() - startTime < 60000) {
      const { code, message, data } = await rebalance.getRebalanceStatus(clusterID);
      if (code !== 200) {
        console.log("Failed to fetch rebalance status:", message);
        break;
      }
      if (isRebalanceComponentReady(data)) {
        componentOK = true;
        break;
      }
      // wait 1s
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    setTimeout(() => {
      if (componentOK) {
        setRebalanceState(readyToRebalance);
      } else {
        setRebalanceState(tryAgain);
      }
    }, 2000);
  }

  const availableSavingPercent = Math.floor(
    (clusterSummary.estimateMonthlySaving / clusterSummary.monthlyCost) * 100
  );

  const currentNodes = clusterOptimization.currentNodes.reduce(
    (a, b) => a + b.count,
    0
  );
  // We may not find the best selection, but close to it.
  if (clusterSummary.estimateMonthlySaving - 0.0 < 0.00001 || !clusterOptimization.optimizationNodes) {
    clusterOptimization.optimizationNodes = clusterOptimization.currentNodes;
  }
  const optimizedNodes = clusterOptimization.optimizationNodes.reduce(
    (a, b) => a + b.count,
    0
  );
  const currentCPUCores = clusterOptimization.currentNodes.reduce(
    (a, b) => a + b.cpuCores * b.count,
    0
  );
  const currentMemoryGiBs = clusterOptimization.currentNodes.reduce(
    (a, b) => a + b.ramGiBs * b.count,
    0
  )
  const optimizedCPUCores = clusterOptimization.optimizationNodes.reduce(
    (a, b) => a + b.cpuCores * b.count,
    0
  );
  const optimizedMemoryGiBs = clusterOptimization.optimizationNodes.reduce(
    (a, b) => a + b.ramGiBs * b.count,
    0
  )

  const currentMonthlySpend = clusterOptimization.currentNodes.reduce(
    (a, b) => a + b.monthlyCost * b.count,
    0
  );
  const optimizedMonthlySpend = clusterOptimization.optimizationNodes.reduce(
    (a, b) => a + b.monthlyCost * b.count,
    0
  );

  const currentSpot = clusterOptimization.currentNodes.reduce(
    (a, b) => a + (b.capacityType === "SPOT" ? 1 : 0),
    0
  );
  const optimizedSpot = clusterOptimization.optimizationNodes.reduce(
    (a, b) => a + (b.capacityType === "SPOT" ? b.count : 0),
    0
  );
  const symbol = getCostSymbol(clusterSummary.region);

  return (
    <div className="w-full h-full">
      <Modal
        title="Connect your cluster"
        open={addRebalance}
        onCancel={() => {
          setAddRebalance(false);
        }}
        footer={[rebalanceState]}
      >
        <p className="font-bold mb-2">
          Open your terminal and run the following command
        </p>
        <p className="mb-2">
          Please make sure kubectl is installed before running the command.
        </p>
        <CodeBlock code={rebalanceSH} />
        <p className="mt-2">
          <span className="font-bold">CloudPilot AI</span> will add necessarily
          privileges automatically.
        </p>
        <p className="mt-2">After the script is completed, you can configure some special workloads to ensure non-interruption in the <span className="font-bold">configuration tab</span>.</p>
      </Modal>
      <div className="flex flex-col bg-white rounded-lg p-6">
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row space-x-8">
            <div className="flex flex-col">
              <Progress
                type="circle"
                percent={availableSavingPercent}
                steps={{ count: 10, gap: 1 }}
                trailColor="#f0f0f0"
                strokeWidth={20}
                strokeColor="#1677ff"
                size={90}
              />
            </div>
            <div className="flex flex-col space-y-4">
              <h1 className="text-lg font-bold">
                You Can Save {availableSavingPercent}%!
              </h1>
              <span className="flex flex-row items-center justify-center">
                Get available saving now.{" "}
                <Tag className="text-lg font-bold" color="geekblue"> {symbol + currentMonthlySpend.toFixed(3)}</Tag>
                <FontAwesomeIcon icon={faArrowRight}/>
                <Tag className="text-lg font-bold ml-2" color="volcano"> {symbol + optimizedMonthlySpend.toFixed(3)}</Tag>
              </span>
            </div>
          </div>
          <div className="flex flex-row space-x-8">
            <div className="flex flex-col space-y-4">
              <span>Monthly Saving</span>
              <h1 className="text-lg font-bold">
                {symbol + clusterSummary.estimateMonthlySaving.toFixed(3)}
              </h1>
            </div>
            <div className="flex flex-col space-y-4">
              <span>Annual Saving</span>
              <h1 className="text-lg font-bold">
                {symbol + (clusterSummary.estimateMonthlySaving * 12).toFixed(3)}
              </h1>
            </div>
            <div className="flex flex-col space-y-4 items-center justify-center">
              <Button
                type="primary"
                onClick={() => {
                  setAddRebalance(true);
                }}
              >
                Start Saving
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-8 rounded-lg">
        <div className="w-full flex flex-col justify-between bg-white mr-3">
          <ClusterConfiguration
            title="Current cluster configuration"
            currentNodes={currentNodes}
            currentCPUCores={currentCPUCores}
            currentMemoryGiBs={currentMemoryGiBs}
            currentMonthlySpend={currentMonthlySpend}
            currentSpot={currentSpot}
            nodesConfiguration={clusterOptimization.currentNodes}
            symbol={symbol}
          />
        </div>
        <div className="flex items-center w-6">
          <FontAwesomeIcon
            icon={faCircleChevronRight}
            size="2x"
            color="#2564eb"
          />
        </div>
        <div className="w-full flex flex-col justify-between bg-white ml-3">
          <ClusterConfiguration
            title="Optimized cluster configuration"
            currentNodes={optimizedNodes}
            currentCPUCores={optimizedCPUCores}
            currentMemoryGiBs={optimizedMemoryGiBs}
            currentMonthlySpend={optimizedMonthlySpend}
            currentSpot={optimizedSpot}
            nodesConfiguration={clusterOptimization.optimizationNodes}
            symbol={symbol}
          />
        </div>
      </div>
    </div>
  );
}
