function VerifyEmail() {
    return (
        <div className="flex flex-col bg-white rounded-lg p-6 mt-40 pt-36">
            <div className="flex flex-row w-full justify-center items-center mb-10">
                <img alt="logo" className="w-24 h-24 object-center mx-auto" src="/email.png" />
            </div>
            <div className="flex flex-row w-full justify-center items-center mb-10">
                <p className="text-lg text-center">
                    <b>We have sent an email to you for verification. Please verify it and then click </b>
                    <a href="/" className="underline text-sky-700 font-bold">here</a>.
                </p>
            </div>
        </div>
    );
}

export const email = {
    VerifyEmail,
}