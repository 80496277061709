import { faCopy, faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Highlight, themes } from "prism-react-renderer";
import { useState } from "react";

interface CodeBlackProps {
    code: string
}

export default function CodeBlock({code}: CodeBlackProps) {
    const [ico, setIco] = useState(faCopy);
    function handleCodeBlockCopy() {
        navigator.clipboard.writeText(code);
        setIco(faFileCircleCheck);
        setTimeout(() => {
            setIco(faCopy);
        }, 2000);
    }
    return (
        <Highlight
            theme={themes.vsDark}
            code={code}
            language="shell"
        >
            {({ style, tokens, getLineProps, getTokenProps }) => (
                <div className="static w-full relative">
                    <FontAwesomeIcon
                        className="absolute top-1 right-1 text-slate-200 text-base hover:cursor-pointer hover:text-white"
                        icon={ico}
                        onClick={handleCodeBlockCopy}/>
                    <pre style={style} className="p-2 text-wrap rounded">
                        {tokens.map((line, i) => (
                        <div key={i} {...getLineProps({ line })}>
                            {line.map((token, key) => (
                            <span key={key} {...getTokenProps({ token })} />
                            ))}
                        </div>
                        ))}
                    </pre>
                </div>
            )}
        </Highlight>
    )
}