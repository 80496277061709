import { ProColumns, ProTable } from "@ant-design/pro-components";

import { ActiveSavingPlan } from "../type/clusterbilling";

interface SavingsPlanListProps {
    savingsPlan: ActiveSavingPlan[] | undefined;
    symbol: string;
}

export default function SavingsPlanList({ savingsPlan, symbol }: SavingsPlanListProps) {
      const columns: ProColumns<ActiveSavingPlan>[] = [
        {
            title: "Savings Plan ID",
            dataIndex: "id",
            key: "id",
            editable: false,
            search: false,
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            editable: false,
            search: false,
        },
        {
            title: "Instance family",
            dataIndex: "instanceFamily",
            key: "instanceFamily",
            editable: false,
            search: false,
            render: (_, record) => {
                return record.instanceFamily !== undefined ? record.instanceFamily : "-";
            }
        },
        {
            title: "Region",
            dataIndex: "region",
            key: "region",
            editable: false,
            search: false,
            render: (_, record) => {
                return record.region !== undefined ? record.region : "-";
            }
        },
        {
            title: "Commitment",
            dataIndex: "commitment",
            key: "commitment",
            search: false,
            valueType: "switch",
            initialValue: true,
            render: (_, record) => {
               return symbol+Number(record.commitment).toFixed(3)+"/hour";
            },
        },
        {
            title: "Commitment Usage",
            dataIndex: "usage",
            key: "usage",
            search: false,
            valueType: "switch",
            initialValue: true,
            render: (_, record) => {
               return symbol+Number(record.usage).toFixed(3)+"/hour";
            },
        },
        {
            title: "Start date (UTC)",
            dataIndex: "spotFriendly",
            key: "spotFriendly",
            search: false,
            render: (_, record) => {
                return record.startDate;
            },
        },
        {
            title: "End date (UTC)",
            dataIndex: "minNonSpotReplicas",
            key: "minNonSpotReplicas",
            search: false,
            valueType: 'digit',
            render: (_, record) => {
                return record.endDate;
            },
        },
    ]

    return (
        <ProTable<ActiveSavingPlan>
            cardBordered
            search={false}
            request={async (params, sort, filter) => {
                return { data: savingsPlan, success: true };
            }}
            rowKey={(record) => record.id}
            columns={columns}
            options={{ reload: true, density: true, setting: false, reloadIcon: false }}
            scroll={{ x: 40 }}
            dateFormatter="string"
            headerTitle="Savings Plan List(Order by applying priority)"
        />
    );
}