import { Col, Row } from "antd";

export default function CoreFeature() {
    return (
        <div className="bg-white p-3 rounded-lg">
            <p className="font-bold text-base mb-4 border-b border-[#f0f0f0] border-[#1px] pb-2">What can CloudPilot AI do for you?</p>
            <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                    <div className="p-3 w-full flex flex-col items-center bg-[#f0f9ff] rounded-lg">
                        <p className="text-base mb-4 text-center whitespace-pre-wrap text-sky-700">AI-Driven node selection</p>
                        <img src="/icon/aidrivennodeselection.png" alt="logo" className="w-full max-w-48" />
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div className="p-3 w-full flex flex-col items-center bg-[#f0f9ff] rounded-lg">
                        <p className="text-base mb-4 text-center whitespace-pre-wrap text-sky-700">Node right-sizing</p>
                        <img src="/icon/noderightsizing.png" alt="logo" className="w-full max-w-48" />
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div className="p-3 w-full flex flex-col items-center bg-[#f0f9ff] rounded-lg">
                        <p className="text-base mb-4 text-center whitespace-pre-wrap text-sky-700">Cost-aware pod scheduling</p>
                        <img src="/icon/costawarepodscheduling.png" alt="logo" className="w-full max-w-48" />
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div className="p-3 w-full flex flex-col items-center bg-[#f0f9ff] rounded-lg">
                        <p className="text-base mb-4 text-center whitespace-pre-wrap text-sky-700">Ensure workload availability</p>
                        <img src="/icon/ensureworkloadavailability.png" alt="logo" className="w-full max-w-48" />
                    </div>
                </Col>
            </Row>
            <Row gutter={16} className="mt-3">
                <Col className="gutter-row" span={6} />
                <Col className="gutter-row" span={6}>
                    <div className="p-3 w-full flex flex-col items-center bg-[#f0f9ff] rounded-lg">
                        <p className="text-base mb-4 text-center whitespace-pre-wrap text-sky-700">Efficient node management</p>
                        <img src="/icon/efficientnodemanagement.png" alt="logo" className="w-full max-w-48" />
                    </div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div className="p-3 w-full flex flex-col items-center bg-[#f0f9ff] rounded-lg">
                        <p className="text-base mb-4 text-center whitespace-pre-wrap text-sky-700">Storage optimization</p>
                        <img src="/icon/storageoptimization.png" alt="logo" className="w-full max-w-48" />
                    </div>
                </Col>
                <Col className="gutter-row" span={6} />
            </Row>
        </div>
    );
}