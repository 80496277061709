import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, TableColumnProps, TableProps, Tag } from "antd";

import { NodeInfo } from "../type/clustercostoptimization";

interface ClusterConfigurationProps {
    title: string;
    currentNodes: number;
    currentCPUCores: number;
    currentMemoryGiBs: number;
    currentMonthlySpend: number;
    currentSpot: number;
    nodesConfiguration: NodeInfo[];
    symbol: string;
}

interface ClusterConfigurationSimpleProps {
    title: string;
    nodesConfiguration: NodeInfo[];
    symbol: string;
}


const baseColumns: TableColumnProps<NodeInfo>[] = [
    {
        title: "QTY",
        key: `count`,
        render: (_ , { count }) => (
            <span>{count}x</span>
        ),
    },
    {
        title: "Type",
        key: "type",
        render: (_ , { instanceType, capacityType, cpuCores, ramGiBs, GPUCards }) => (
            <span key="type">
                <Tag color="green" key="instanceType">{instanceType}</Tag>
                <Tag color="blue" key="capacity">{cpuCores}C {ramGiBs}G</Tag>
                {GPUCards === 0 ? <></> : <Tag color="#f6dd96" key="gpu">GPU</Tag>}
                <Tag color="volcano" key="capacityType">{capacityType}</Tag>
            </span>
        ),
    },
]


function getTabelColumns(symbol: string) {
    const columns: TableProps<NodeInfo>['columns'] = [
        ...baseColumns,
        {
            title: "CPU Cost",
            dataIndex: "cpuCostPerHur",
            sorter: (a, b) => a.CPUCostPerHur - b.CPUCostPerHur,
            render: (_ , { count, CPUCostPerHur }) => (
                <span key="cpucost">{symbol + (CPUCostPerHur * count).toFixed(3)}/h</span>
            ),
        },
        {
            title: "Monthly Cost",
            dataIndex: "monthlyCost",
            sorter: (a, b) => a.monthlyCost - b.monthlyCost,
            render: (_ , { count, monthlyCost }) => (
                <span key="monthlycost">{symbol + (monthlyCost * count).toFixed(3)}/mo</span>
            ),
        }
    ];

    return columns;
}

export function ClusterConfiguration({title, currentNodes,
    currentCPUCores, currentMemoryGiBs, currentMonthlySpend, currentSpot,
    nodesConfiguration, symbol}: ClusterConfigurationProps) {
    const columns = getTabelColumns(symbol);

    return (
        <>
            <div className="w-full flex flex-row justify-between border-b-[1px] pb-4">
                <div className="flex flex-col pl-3 pt-3">
                    <span className="text-base font-bold">{title}</span>
                </div>
                <div className="flex flex-col pr-3 pt-3">
                    <FontAwesomeIcon icon={faSliders} />
                </div>
            </div>
            <div className="w-full flex flex-row justify-between pl-8 pr-8 pt-4">
                <div className="flex flex-col space-y-4">
                    <span>Nodes</span>
                    <h1 className="text-lg font-bold">{currentNodes}</h1>
                </div>
                <div className="flex flex-col space-y-4">
                    <span>CPU(Core)</span>
                    <h1 className="text-lg font-bold">{currentCPUCores}</h1>
                </div>
                <div className="flex flex-col space-y-4">
                    <span>Memory(GiB)</span>
                    <h1 className="text-lg font-bold">{currentMemoryGiBs}</h1>
                </div>
                <div className="flex flex-col space-y-4">
                    <span>Monthly Spend</span>
                    <h1 className="text-lg font-bold">{symbol + currentMonthlySpend.toFixed(3)}</h1>
                </div>
                <div className="flex flex-col space-y-4">
                    <span>Spot</span>
                    <h1 className="text-lg font-bold">{currentSpot}</h1>
                </div>
            </div>
            <div className="w-full flex flex-col mt-3 p-3">
                <Table
                    columns={columns}
                    rowKey={(record) => record.instanceType + "/" + record.capacityType}
                    dataSource={nodesConfiguration}
                    pagination={{
                        pageSize: 10,
                        onChange: (page) => console.log(page),
                    }}
                />
            </div>
        </>
    )
}

export function ClusterConfigurationSimple({title, nodesConfiguration, symbol}: ClusterConfigurationSimpleProps) {
    const columns = getTabelColumns(symbol);

    return (
        <>
            <div className="w-full flex flex-row justify-between border-b-[1px] pb-4">
                <div className="flex flex-col pl-3 pt-3">
                    <span className="text-base font-bold">{title}</span>
                </div>
                <div className="flex flex-col pr-3 pt-3">
                    <FontAwesomeIcon icon={faSliders} />
                </div>
            </div>
            <div className="w-full flex flex-col mt-3 p-3">
                <Table
                    columns={columns}
                    rowKey={(record) => record.instanceType + "/" + record.capacityType}
                    dataSource={nodesConfiguration}
                    pagination={{
                        pageSize: 10,
                        onChange: (page) => console.log(page),
                    }}
                />
            </div>
        </>
    )
}
