import { server } from "../config/props";
import { ClusterCostsSummary } from "../type/clustercostssummary";
import { Response } from "../type/common";
import { requests } from "../utils/fetch";

async function listAllClusterCostsSummary(): Promise<Response<ClusterCostsSummary[]>> {
    return requests.get<ClusterCostsSummary[]>(`${server}/api/v1/costs/clusters/summary`)
}

async function getClusterCostsSummary(clusterID: string): Promise<Response<ClusterCostsSummary>> {
    return requests.get<ClusterCostsSummary>(`${server}/api/v1/costs/clusters/${clusterID}/summary`);
}

export const clustercostssummary = {
    listAllClusterCostsSummary,
    getClusterCostsSummary,
}