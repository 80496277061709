import { requests } from "../utils/fetch";
import { Response } from "../type/common";
import { ClusterRebalanceStatus, ClusterRebalanceConfiguration } from "../type/rebalance";
import { ClusterWorkload, Workload } from "../type/clusterworkload";
import { server } from "../config/props";

async function getRebalanceConfiguration(clusterID: string): Promise<Response<ClusterRebalanceConfiguration>> {
    return requests.get(`${server}/api/v1/rebalance/clusters/${clusterID}/configuration`);
}

async function updateRebalanceConfiguration(clusterID: string, cfg: ClusterRebalanceConfiguration): Promise<Response<string>> {
    return requests.post(`${server}/api/v1/rebalance/clusters/${clusterID}/configuration`, cfg);
}

async function getRebalanceStatus(clusterID: string): Promise<Response<ClusterRebalanceStatus>> {
    return requests.get(`${server}/api/v1/rebalance/clusters/${clusterID}/status`);
}

async function getWorkloadRebalanceConfiguration(clusterID: string): Promise<Response<ClusterWorkload>> {
    return requests.get(`${server}/api/v1/rebalance/clusters/${clusterID}/workloads/configuration`);
}

async function updateWorkloadRebalanceConfiguration(clusterID: string, cfg: Workload): Promise<Response<string>> {
    return requests.post(`${server}/api/v1/rebalance/clusters/${clusterID}/workloads/configuration`, cfg);
}

export const rebalance = {
    getRebalanceStatus,
    updateRebalanceConfiguration,
    getRebalanceConfiguration,
    getWorkloadRebalanceConfiguration,
    updateWorkloadRebalanceConfiguration,
}