interface ComingSoonProps {
    info: string;
}


export default function ComingSoon({ info } : ComingSoonProps) {
    return (
        <div className="flex flex-col bg-white rounded-lg p-6 mt-40 pt-36">
            <div className="flex flex-row w-full justify-center items-center mb-10">
                <img alt="logo" className="h-24 object-center mx-auto" src="/coming-soon.png" />
            </div>
            <div className="flex flex-row w-full justify-center items-center mb-10">
                <span className="text-lg">
                    <b>{info}</b>
                </span>
            </div>
        </div>
    )
}