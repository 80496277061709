const userKey = {
  getToken(): string | null {
    return localStorage.getItem("token");
  },
  setToken(token: string) {
    localStorage.setItem("token", token);
  },
  clear() {
    localStorage.clear();
  },
};

export { userKey };
