import { server } from "../config/props";
import { Response } from "../type/common";
import { requests } from "../utils/fetch";

async function getAgentSH(): Promise<Response<string>> {
    return requests.get(`${server}/api/v1/agent/sh`)
}

export const agentsh = {
    getAgentSH,
}