import { PageContainer, ProLayout } from "@ant-design/pro-components";
import { getClusterLayoutProps } from "../config/props";
import { Outlet, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useParams } from 'react-router-dom';

import { userKey } from "../storage";
import { RequireAuth } from "../components/Auth";
import { useAuth0 } from "@auth0/auth0-react";


export default function ClusterLayout() {
    const { clusterID } = useParams();
    const { logout, user } = useAuth0();

    const [avatarImg, setAvatarImg] = useState(user?.picture);
    const [avatarName, setAvatarName] = useState(user?.nickname);
    const [pathName, setPathName] = useState("/cluster");

    useEffect(() => {
      if (!user) {
        return;
      }
      setAvatarImg(user?.picture || "");
      setAvatarName(user?.nickname || "");
    }, [user])

    const navigate = useNavigate();
    if (!clusterID) {
      navigate("/clusterlist");
      return <></>;
    }

    const props = getClusterLayoutProps(clusterID);
    return (
      <RequireAuth>
        <ProLayout
          siderWidth={216}
          logo="/logo.png"
          title="CloudPilot AI"
          pageTitleRender={(props) => {
            return ""
          }}
          avatarProps={{
            src: avatarImg,
            title: avatarName,
            size: "default",
          }}
          location={{pathname: pathName}}
          actionsRender={(props) => {
            return [
              <FontAwesomeIcon icon={faRightFromBracket} onClick={() => {
                userKey.clear();
                logout({ logoutParams: { returnTo: window.location.origin } });
              }} color="#1677ff" />
            ];
          }}
          menuItemRender={(item, defaultDom) => (
            <Link to={item.path || '/cluster'} onClick={() => (setPathName(item.path || "/"))}>
                {defaultDom}
            </Link>
          )}
          {...props}
        >
            <PageContainer className="h-full">
                <Outlet/>
            </PageContainer>
        </ProLayout>
      </RequireAuth>
    );
  }